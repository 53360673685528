.content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px; /* Ensure it's wide enough for the inline layout */
    margin: 0 auto;
    padding: 20px;
    overflow-y: auto; /* Allow vertical scrolling for overflow content */
    height: calc(100vh - 40px); /* Fill the viewport minus padding */
    background: linear-gradient(26.27deg, #0A0A0A 25.38%, #123524 65.94%, #145A38 106.63%);
    border-radius: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); /* Add subtle shadow */
    box-sizing: border-box; /* Include padding in height calculations */
}

.mix-section {
    display: flex;
    align-items: center;
    justify-content: center; /* Centers horizontally */
    margin: 0 auto;
    gap: 10px; /* Spacing between elements */
    margin: 5px 10px 20px 5px;
    padding: 10px;
    border-radius: 5px;
  }
  
  .mix-section label {
    color: #FFFFFF; /* White text for contrast */
    font-size: 14px;
  }
  
  .mix-section input {
    text-align: center;
    padding: 5px;
    font-size: 14px;
    border: 1px solid #123524; /* Match theme */
    border-radius: 5px;
    background-color: #0A0A0A;
    color: #FFFFFF; /* White text for input */
  }
  
  .mix-section input:focus {
    outline: none;
    border-color: #145A38; /* Highlight color on focus */
    box-shadow: 0 0 5px #145A38;
  }
  
  .mix-section button {
    height: 30px;
    padding: 0 15px;
    font-size: 12px;
    font-weight: bold;
    color: #FFFFFF; /* White text for button */
    background-color: #123524;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .mix-section button:hover {
    background-color: #145A38; /* Slightly lighter green on hover */
  }
  



/* Overall container for centering and spacing */
.search-container, .ai-container {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Space out input and button */
    padding: 20px;
    max-width: 800px;
    margin: 0 auto; /* Center the container horizontally */
    background: linear-gradient(26.27deg, #0A0A0A 45.38%, #123524 120.94%, #145A38 140.63%);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    width: 100%; /* Full width for parent alignment */
    max-width: 600px; /* Same max-width for all containers */
    padding: 20px; /* Internal spacing */
    margin: 20px auto; /* Center horizontally with spacing between containers */
    box-sizing: border-box; /* Consistent sizing */
}

/* Header styles */
.search-container h1 {
    font-family: andale-mono, monospace;
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #E0E0E0; /* Muted light text */
    text-align: center;
}

/* Input styles */
/* Flex layout for search bar and button */
.search-bar-wrapper {
    display: flex; /* Align items horizontally */
    gap: 10px; /* Add space between the search bar and button */
    width: 100%; /* Ensure it stretches to fit the container width */
    justify-content: center; /* Center horizontally within the parent container */
}

.search-bar-wrapper input[type="text"] {
    flex-grow: 1; /* Let the search bar take up most of the space */
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #1A1A1A; /* Muted dark input background */
    color: #E0E0E0; /* Muted light text */
}

.search-bar-wrapper button {
    padding: 0px 20px;
    background-color: #123524; /* Pthalo Green */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.8rem;
    transition: background-color 0.3s;
}

.search-bar-wrapper button:hover {
    background-color: #145A38; /* Slightly lighter Pthalo Green on hover */
}

/* Dropdown styles */
.search-container select {
    font-size: 1rem;
    padding: 10px;
    color: #000;
    background-color: #E0E0E0; /* Muted light background */
    border: none;
    border-radius: 5px;
    margin: 10px 0;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    outline: none;
}

.search-container select:focus {
    border: 2px solid #123524; /* Pthalo Green focus border */
}



.ai-container p{
    font-size: 0.8rem;
    align-items: left;
    justify-content: left;
    
}

/* Results section */
  /* Individual containers */
  .results-container, .pot-container {
    flex: 1; /* Let both containers take up equal space */
    max-width: 50%; /* Restrict each to half of the available width */
    padding: 20px; /* Add padding inside the containers */
    box-sizing: border-box; /* Ensure padding doesn’t overflow */
    background: #0A0A0A; /* Background color matching theme */
    border-radius: 10px; /* Rounded corners */
    overflow-y: auto; /* Enable scrolling if content overflows */
  }

.results-container h2, .pot-container  h2 {
    font-size: 1.5rem;
    color: #E0E0E0; /* Muted light text */
    border-bottom: 1px solid #145A38; /* Subtle Pthalo Green underline */
    padding-bottom: 5px;
}

.results-container ul, .pot-container  ul {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
}

.results-container li, .pot-container  li {
    font-size: 0.8rem;
    color: #E0E0E0; /* Muted light text */
    padding: 5px 10px;
    margin-bottom: 8px;
    background-color: #1A1A1A; /* Muted dark list item background */
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.results-container li button, .pot-container  li button {
    font-size: 0.7rem;
    padding: 5px 8px;
    color: #E0E0E0; /* Muted light text */
    background-color: #123524; /* Pthalo Green */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}

.results-container li button:hover, .pot-container li button:hover {
    background-color: #145A38; /* Slightly lighter Pthalo Green */
    transform: scale(1.05);
}

/* Wrapper for the results and pot containers */
.results-pot-container {
    display: flex; /* Enable Flexbox for horizontal alignment */
    flex-direction: row; /* Place items side by side */
    gap: 20px; /* Space between the two columns */
    justify-content: center; /* Distribute space evenly */
    align-items: flex-start; /* Align both containers at the top */
    width: 100%; /* Ensure the wrapper spans the full width */
    margin-top: 20px; /* Add space above the section */
    box-sizing: border-box; /* Ensure padding is included in total width */
    
  }
  

  
/* Mobile-specific styles for screens 600px or smaller */
/* Mobile-specific styles for screens 600px or smaller */
@media (max-width: 600px) {
  /* General container adjustments */
  .content-wrapper {
    padding: 10px; /* Reduce padding for small screens */
    height: 100vh; /* Make it exactly the height of the viewport */
    max-width: 100%; /* Remove the max-width restriction */
    border-radius: 0; /* Remove rounded corners for a full-width look */
    overflow-y: auto; /* Ensure scrolling is allowed if content overflows */
    margin: 0; /* Remove horizontal centering on small screens */
  }

  .search-container, .ai-container{
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    justify-content: space-between; /* Space out input and button */
    background: linear-gradient(26.27deg, #0A0A0A 45.38%, #123524 120.94%, #145A38 140.63%);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    position: relative; /* Position it relative to the results-container */
    z-index: 10; /* Keep it above the results content */
    max-width: 100%; /* Remove the width restriction */
    width: 100%; /* Ensure it spans the full width of the viewport */
    margin: 10px auto; /* Remove horizontal centering */
  
  }

  .search-container h1, .ai-container h1 {
    font-family: andale-mono, monospace;
    font-size: 0.9rem;
    margin-bottom: 15px;
    color: #E0E0E0; /* Muted light text */
    text-align: center;
}

.search-bar-wrapper input[type="text"] , .ai-container input[type="text"] {
  width: 90%; /* Make the input span most of the screen */
  max-width: 500px; /* Limit maximum width */
  margin: 0 auto; /* Center horizontally */
  padding: 10px; /* Ensure adequate padding */
  font-size: 0.8rem; /* Adjust font size for better readability */
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #1A1A1A; /* Muted dark input background */
  color: #E0E0E0; /* Muted light text */
  box-sizing: border-box; /* Include padding in width calculation */
}


  /* Stack search and results vertically */
  .results-pot-container {
    flex-direction: column; /* Stack items vertically */
    gap: 10px; /* Add space between stacked items */
  }

  .results-container, .pot-container {
    width: 100%; /* Full width of the parent */
    max-width: 100%; /* Remove the max-width restriction */
    padding: 10px;
    font-size: 0.9rem; /* Slightly smaller text for mobile */
  }

  /* Reduce header sizes for better spacing */
  h1 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 1rem;
    margin-bottom: 8px;
    text-align: center; /* Center-align headers */
  }

  /* Dropdown adjustments */
  .search-container select {
    font-size: 0.9rem; /* Slightly smaller for mobile */
    width: 50%; /* Ensure it spans full width */
    padding: 5px;
    margin: 0 auto 0 auto;
  }
  .filter {
    font-size: 0.65rem;
    display: flex; /* Use flexbox for inline layout */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Align vertically */
    gap: 10px; /* Add space between label and dropdown */
    margin: 0 auto; /* Center the container itself */
    width: 100%; /* Optional: Let it take the full width of its parent */
    text-align: center; /* Center text inside the container */
  }

  /* Buttons should have consistent size and padding */
  .search-bar-wrapper button,
  .mix-section button {
    margin: 5px auto 10px auto;
    padding: 5px 10px;
    font-size: 0.7rem;
    width: 100%; /* Ensure buttons adjust to content */
  }

  .results-container li button,
  .pot-container li button {
    margin: 5px 0 10px auto;
    padding: 5px 10px;
    font-size: 0.7rem;
    width: 15%; /* Ensure buttons adjust to content */
  }

  /* Input fields should also fit mobile */
  .search-bar-wrapper input[type="text"],
  .mix-section input {
    font-size: 0.8rem;
    padding: 8px;
    width: 100%; /* Full width on mobile */
  }

  /* Search bar and dropdown adjustments */
  .search-bar-wrapper {
    flex-direction: column; /* Stack input and button */
    gap: 8px; /* Add space between elements */
  }

  /* AI suggestions adjustments */
  .ai-container {
    padding: 10px;
    font-size: 0.9rem; /* Adjust text size */
  }

  /* Results container adjustments */
  .results-container, .pot-container {
    padding: 10px;
    max-height: none; /* Allow full height for better usability */
    margin: 0; /* Remove additional margins */
    border-radius: 15px; /* Remove rounded corners for a flush appearance */
  }

  .results-pot-container h2{
    font-size: 1rem;
  }

  /* Reduce margins for smaller screens */
  .results-container ul, .pot-container ul {
    margin: 5px 0;

  }

  /* Ensure the list items are properly sized */
  .results-container li, .pot-container li {
    font-size: 0.7rem;
    padding: 8px;
    margin-bottom: 5px;
  }

  .mix-section label{
    font-size:0.65rem;
  }
}


  


